import { STORAGE_KEYS } from '_constants/storage';
import { PROCESSING_CODES } from '_constants/processing';
import { PRODUCT_CODES, LINKED_PRODUCT_CODES } from '_constants/products';
import { FIELDS_KEYS } from '_constants/forms';
import { restoreLocalValue } from '_helpers/storage';

export const createUpsellBody = (
  form = {},
  orderAfterPayment,
  activeProductCode
) => {
  if (!form.products?.length) {
    return null;
  }

  const products = [];

  const primaryProduct = orderAfterPayment?.products?.find(
    ({ code: { code } }) => code === activeProductCode
  );

  form.products?.forEach((code) => {
    const state = primaryProduct?.organizedState;
    const product = { code, state };

    if (code === PRODUCT_CODES.incCertifiedCopy) {
      products.push(product);
      products.push({
        code: LINKED_PRODUCT_CODES.incCertifiedCopyStateFee,
        state,
      });
    } else if (code === PRODUCT_CODES.accountingMonthlyBookkeeping) {
      products.push(product);
      products.push({ code: PRODUCT_CODES.accountingPersonalTaxFiling, state });
      products.push({ code: PRODUCT_CODES.accountingLLCTaxFiling, state });
    } else if (code === PRODUCT_CODES.incBOI) {
      if (
        form[FIELDS_KEYS.dateOfBirthDay] &&
        form[FIELDS_KEYS.dateOfBirthMonth] &&
        form[FIELDS_KEYS.dateOfBirthYear]
      ) {
        const yyyy = form[FIELDS_KEYS.dateOfBirthYear];
        const MM = form[FIELDS_KEYS.dateOfBirthMonth];
        const dd =
          form[FIELDS_KEYS.dateOfBirthDay] < 10
            ? `0${form[FIELDS_KEYS.dateOfBirthDay]}`
            : form[FIELDS_KEYS.dateOfBirthDay];

        product.dateOfBirth = `${yyyy}-${MM}-${dd}`;
      }

      if (form[FIELDS_KEYS.ssn]) {
        product.ssn = form[FIELDS_KEYS.ssn];
      }

      if (form[FIELDS_KEYS.driverLicenceOrID]) {
        product.driverLicenceOrID = form[FIELDS_KEYS.driverLicenceOrID];
      }

      products.push(product);
    } else {
      products.push(product);
    }
  });

  return {
    order: {
      processingOption: PROCESSING_CODES.upsell,
    },
    products,
  };
};

export const fillUpsellBodyWithSourceData = (upsellBody) => {
  if (restoreLocalValue(STORAGE_KEYS.gclid)) {
    upsellBody.order.clid = restoreLocalValue(STORAGE_KEYS.gclid);
  }

  if (restoreLocalValue(STORAGE_KEYS.source)) {
    upsellBody.order.source = restoreLocalValue(STORAGE_KEYS.source);
  }

  if (restoreLocalValue(STORAGE_KEYS.keyword)) {
    upsellBody.order.keyword = restoreLocalValue(STORAGE_KEYS.keyword);
  }

  if (restoreLocalValue(STORAGE_KEYS.adAccount)) {
    upsellBody.order.adAccount = restoreLocalValue(STORAGE_KEYS.adAccount);
  }
};
