import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import union from 'lodash.union';

import { MainApiService } from '_services';
import { STORAGE_KEYS } from '_constants/storage';
import { PROCESSING_CODES } from '_constants/processing';
import { PRODUCT_CODES } from '_constants/products';
import { FIELDS_KEYS } from '_constants/forms';
import { FILE_TYPES } from '_constants/file';
import { processResponse, processError } from '_helpers/api';
import { sanitizeFileName } from '_helpers/file';

import {
  storeSessionValue,
  restoreSessionValue,
  restoreLocalValue,
} from '_helpers/storage';

import {
  createUpsellBody,
  fillUpsellBodyWithSourceData,
} from '_helpers/upsell';

const initForm = {
  step: 0,
  maxStep: 0,
  organizedState: '',
  processingOption: PROCESSING_CODES.gold,
  products: [],
  purchasedProducts: [],
  salesTaxPermit: false,
  sCorp: false,
  certifiedCopy: false,
  bookkeeping: false,
  boi: false,
  dateOfBirthDay: null,
  dateOfBirthMonth: null,
  dateOfBirthYear: null,
  ssn: null,
  driverLicenceOrID: null,
  file: null,
  token: null,
  delayed: false,
};

export const createUpsell = createAsyncThunk(
  'upsell/createUpsell',
  async function (params, { dispatch, getState, rejectWithValue }) {
    const { redirectUrl, delayed } = params || {};
    const crmSessionId = restoreLocalValue(STORAGE_KEYS.crmSessionId);
    const { payment, upsell, products } = getState();
    const { data: orderAfterPayment } = payment;
    const { form } = upsell;
    const { activeProductCode } = products;

    const body = createUpsellBody(form, orderAfterPayment, activeProductCode);

    if (!body) {
      if (redirectUrl) {
        Router.push(redirectUrl);
      }

      return;
    }

    fillUpsellBodyWithSourceData(body);

    const create =
      delayed || form?.delayed
        ? MainApiService.createDelayedUpsell
        : MainApiService.createUpsell;

    try {
      const response = await create({
        crmSessionId,
        orderId: orderAfterPayment?.order?.id,
        body,
      });

      const result = processResponse(response);
      const upsellInfo = restoreSessionValue(STORAGE_KEYS.upsellInfo) || {};

      const prevPurchasedProducts =
        upsellInfo[activeProductCode]?.purchasedProducts || [];

      const newPurchasedProducts = result?.products?.map(
        ({ code }) => code?.code
      );

      const purchasedProducts = union(
        prevPurchasedProducts,
        newPurchasedProducts
      );

      if (form[FIELDS_KEYS.boi] && form[FIELDS_KEYS.file] && form.token) {
        const boiProductId = result?.products?.find(
          ({ code }) => code?.code === PRODUCT_CODES.incBOI
        )?.id;

        if (boiProductId) {
          dispatch(
            uploadFile({
              token: form.token,
              orderId: orderAfterPayment?.order?.id,
              productId: boiProductId,
              file: form[FIELDS_KEYS.file],
              fileType: FILE_TYPES.boiId,
            })
          );

          dispatch(refreshForm({ [FIELDS_KEYS.file]: null }));
        }
      }

      dispatch(storeOrderAfterUpsell(result));
      dispatch(refreshForm({ purchasedProducts }));

      if (redirectUrl) {
        Router.push(redirectUrl);
      }

      return result;
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const uploadFile = createAsyncThunk(
  'upsell/uploadFile',
  async function (
    { orderId, productId, file, fileType, token },
    { rejectWithValue }
  ) {
    try {
      const formData = new FormData();

      if (file?.name) {
        formData.append('file', file, sanitizeFileName(file.name));
      } else {
        formData.append('file', file);
      }

      MainApiService.token = token;

      const response = await MainApiService.uploadFile({
        orderId,
        productId,
        body: formData,
        fileType,
      });

      MainApiService.token = null;
      return processResponse(response);
    } catch (e) {
      MainApiService.token = null;
      return rejectWithValue(processError(e));
    }
  }
);

const upsellSlice = createSlice({
  name: 'upsell',
  initialState: {
    form: {
      ...initForm,
    },
    file: {
      data: null,
      error: null,
      loading: false,
    },
    data: null,
    error: null,
    loading: false,
    restored: false,
  },
  reducers: {
    refreshForm(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedUpsellInfo =
          restoreSessionValue(STORAGE_KEYS.upsellInfo) || {};

        storeSessionValue(STORAGE_KEYS.upsellInfo, {
          ...storedUpsellInfo,
          [activeProductCode]: {
            ...state.form,
            ...action.payload,
          },
        });
      }

      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    restoreForm(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedUpsellInfo =
          restoreSessionValue(STORAGE_KEYS.upsellInfo) || {};

        state.form = {
          ...state.form,
          ...(storedUpsellInfo[activeProductCode] || initForm),
        };
      }
    },
    resetForm(state) {
      state.form = { ...initForm };
    },
    addToProducts(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedUpsellInfo =
          restoreSessionValue(STORAGE_KEYS.upsellInfo) || {};

        storeSessionValue(STORAGE_KEYS.upsellInfo, {
          ...storedUpsellInfo,
          [activeProductCode]: {
            ...state.form,
            products: [
              ...state.form.products.filter((code) => code !== action.payload),
              action.payload,
            ],
          },
        });
      }

      state.form = {
        ...state.form,
        products: [
          ...state.form.products.filter((code) => code !== action.payload),
          action.payload,
        ],
      };
    },
    removeFromProducts(state, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedUpsellInfo =
          restoreSessionValue(STORAGE_KEYS.upsellInfo) || {};

        storeSessionValue(STORAGE_KEYS.upsellInfo, {
          ...storedUpsellInfo,
          [activeProductCode]: {
            ...state.form,
            products: [
              ...state.form.products.filter((code) => code !== action.payload),
            ],
          },
        });
      }

      state.form = {
        ...state.form,
        products: [
          ...state.form.products.filter((code) => code !== action.payload),
        ],
      };
    },
    storeOrderAfterUpsell(_, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedOrderAfterUpsell =
          restoreSessionValue(STORAGE_KEYS.orderAfterUpsell) || {};

        storeSessionValue(STORAGE_KEYS.orderAfterUpsell, {
          ...storedOrderAfterUpsell,
          [activeProductCode]: {
            ...(action.payload || {}),
          },
        });
      }
    },
    restoreOrderAfterUpsell(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedOrderAfterUpsell =
          restoreSessionValue(STORAGE_KEYS.orderAfterUpsell) || {};
        const storedOrderAfterUpsellByProductCode =
          storedOrderAfterUpsell[activeProductCode] || null;
        state.data = storedOrderAfterUpsellByProductCode;
        state.restored = true;
      }
    },
    resetResult(state) {
      state.data = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUpsell.pending, (state) => {
        state.data = null;
        state.error = null;
        state.loading = true;
        state.restored = false;
      })
      .addCase(createUpsell.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(createUpsell.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(uploadFile.pending, (state) => {
        state.file.error = null;
        state.file.loading = true;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.file.data = action.payload;
        state.file.loading = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.file.error = action.payload;
        state.file.loading = false;
      });
  },
});

export const {
  refreshForm,
  restoreForm,
  resetForm,
  addToProducts,
  removeFromProducts,
  storeOrderAfterUpsell,
  restoreOrderAfterUpsell,
  resetResult,
  resetError,
} = upsellSlice.actions;

export default upsellSlice.reducer;
