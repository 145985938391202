import { config } from '_config';
import { FILE_TYPES } from '_constants/file';
import { AxiosClient } from '../AxiosClient';

const { apiUrl } = config;

class MainApiService extends AxiosClient {
  constructor(url) {
    super(url);
    this.getActivities = this.getActivities.bind(this);
    this.getProcessingOptions = this.getProcessingOptions.bind(this);
    this.getStates = this.getStates.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.getPrices = this.getPrices.bind(this);
    this.calculateFees = this.calculateFees.bind(this);
    this.getOrder = this.getOrder.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
    this.createPayment = this.createPayment.bind(this);
    this.createClick = this.createClick.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.checkCorporationAvailability =
      this.checkCorporationAvailability.bind(this);
    this.getUpsellOffers = this.getUpsellOffers.bind(this);
    this.createUpsell = this.createUpsell.bind(this);
    this.createDelayedUpsell = this.createDelayedUpsell.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.getFileTypes = this.getFileTypes.bind(this);
  }

  getActivities({ category = 'EIN' } = {}) {
    const path = `/category/${category}/products/primary-activity`;
    return this.client.get(path);
  }

  getProcessingOptions({ category = 'INC' } = {}) {
    const path = `/category/${category}/processing-options`;
    return this.client.get(path);
  }

  getStates({ category = 'INC' } = {}) {
    const path = `/category/${category}/states`;
    return this.client.get(path);
  }

  getProducts({ category = 'INC', ...restParams } = {}) {
    const path = `/category/products/codes`;
    return this.client.get(path, { params: { category, ...restParams } });
  }

  getPrices({ category = 'INC', ...params } = {}) {
    const path = `/category/${category}/products/prices`;
    return this.client.get(path, { params });
  }

  calculateFees({ state, codes = [], ...params } = {}) {
    const path = '/category/products/calculate-price';
    return Promise.all(
      codes.map((code) => this.client.post(path, { code, state }, { params }))
    );
  }

  getOrder({ orderId, ...params } = {}) {
    const path = `/orders/${orderId}`;
    return this.client.get(path, { params });
  }

  createOrder({ crmSessionId, body }) {
    const path = '/category/products';
    const params = {};

    if (crmSessionId) {
      params.crmSessionId = crmSessionId;
    }

    return this.client.post(path, body, { params });
  }

  updateOrder({ crmSessionId, body }) {
    const path = '/category/products';
    const params = {};

    if (crmSessionId) {
      params.crmSessionId = crmSessionId;
    }

    return this.client.put(path, body, { params });
  }

  createPayment({ crmSessionId, orderId, body }) {
    const path = `/orders/${orderId}/payment-details`;
    const params = {};

    if (crmSessionId) {
      params.crmSessionId = crmSessionId;
    }

    return this.client.post(path, body, { params });
  }

  createClick({ body }) {
    const path = '/clicks';
    return this.client.post(path, body);
  }

  sendMessage({ body }) {
    const path = '/contact-us';
    return this.client.post(path, body);
  }

  checkCorporationAvailability({ state, name, ...restParams } = {}) {
    const path = `/orders/check-corporation-availability`;
    return this.client.get(path, { params: { state, name, ...restParams } });
  }

  getUpsellOffers({ ...params } = {}) {
    const path = `/upsell-offers`;
    return this.client.get(path, { params });
  }

  createUpsell({ crmSessionId, orderId, body }) {
    const path = `/orders/${orderId}/create-upsale`;
    const params = {};

    if (crmSessionId) {
      params.crmSessionId = crmSessionId;
    }

    return this.client.post(path, body, { params });
  }

  createDelayedUpsell({ crmSessionId, orderId, body }) {
    const path = `/orders/${orderId}/create-delayed-upsale`;
    const params = {};

    if (crmSessionId) {
      params.crmSessionId = crmSessionId;
    }

    return this.client.post(path, body, { params });
  }

  uploadFile({ orderId, productId, body, fileType }) {
    const path = `/orders/${orderId}/products/${productId}/result-PDF`;

    const params = {
      fileType: FILE_TYPES.boiId,
    };

    if (fileType) {
      params.fileType = fileType;
    }

    return this.client.post(path, body, { params });
  }

  getFileTypes({ productCode } = {}) {
    const path = `/category/products/${productCode}/file-types`;
    return this.client.get(path, { params: { uploadable: true } });
  }
}

const ServiceInstance = new MainApiService(apiUrl);

export { ServiceInstance as MainApiService };
